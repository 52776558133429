const featuredDiv = document.getElementById("featured-banner");

function setBgImg() {
  if (featuredDiv) {
    const imgCount = 4;
    const imgDir = "/v5/img/";
    const randomCount = Math.floor((Math.random() * imgCount) + 1);
    let bgImages = [];
    bgImages[1] = "event-banner-01.jpg";
    bgImages[2] = "event-banner-02.jpg";
    bgImages[3] = "event-banner-03.jpg";
    bgImages[4] = "event-banner-04.jpg";

    featuredDiv.style.backgroundImage = "url(" + imgDir + bgImages[randomCount] + ")";
  }
}

$(document).ready(function(){
  setBgImg();
});
